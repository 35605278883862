import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Styled components
const MessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1),
  width: '75%',
  [theme.breakpoints.down('lg')]: {
    width: '100%', // Take up full width on smaller screens
    padding: theme.spacing(1),
  },
}));

const Circle = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: 25,
    height: 25,
  },
}));

const NameLetter = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(16), // Font size for h7
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(20), // Font size for h6
  },
}));

const QuestionBubble = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  borderRadius: 10,
  padding: theme.spacing(2.5),
  paddingTop: theme.spacing(7),
  marginRight: theme.spacing(2),
  maxWidth: '70%',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '90%', // Adjust to fit better on smaller screens
    marginRight: theme.spacing(1), // Adjust right margin for smaller screens
    paddingTop: theme.spacing(7), // Adjust padding for smaller screens
  },
}));

const UsernameTag = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(2),
    fontSize: '0.8rem', // smaller font size for the username
    color: 'white', 
    textTransform: 'uppercase'
  }));

const UserQuestion = ({ username, naturalLanguageQuery }) => {
  return (
    <MessageContainer>
      <QuestionBubble>
        <UsernameTag>{username}</UsernameTag>
        <Typography variant="body1">{naturalLanguageQuery}</Typography>
      </QuestionBubble>
      <Circle>
        <NameLetter color="white">
          {username.charAt(0)}
        </NameLetter>
      </Circle>
    </MessageContainer>
  );
};

export default UserQuestion;
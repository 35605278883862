import React, { useState } from 'react';
import { useQuery } from './QueryContext';
import config from '../utils/config.json';
//import { useResponses } from './ResponsesContext';
import {Paper, TextField, Button, useTheme, useMediaQuery, Typography, Stack} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../styles/bottombar.css';
import { styled } from '@mui/material/styles';
 
// Render the component...
function Query({ language }) {
    const [localQuery, setLocalQuery] = useState('');
    const theme = useTheme();
    
    // Responsive width based on breakpoints
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const textFieldWidth = isSmallScreen
        ? '100%' // Full width on small screens
        : isMediumScreen
        ? '75%' // Less width on medium screens
        : '50%'; // Default width on larger screens

    const SubmitButton = styled(Button)(({ theme }) => ({
        width: '45px', // Width of the button
        height: '45px', // Height of the button
        borderRadius: '50%', // Makes the button circular
        minWidth: 'unset', // Remove default minWidth
        padding: '0', // Remove default padding
        boxShadow: 'none', // Remove default shadow
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '35px',
        marginLeft: '30px',
        position: 'relative',
        top: '20px',
    }));

    // Handler for Change
    const handleChange = event => {
        setLocalQuery(event.target.value);
        autoResizeTextarea(event.target);
    };

    // Function to auto resize text area
    const autoResizeTextarea = textarea => {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    };

    // Handler for Key Down
    const handleKeyDown = event => {
        if (event.key === "Enter") {
            console.log('onKeyDown=Enter');
            event.preventDefault();

            // Broadcast the change to our QueryContext listeners...
            onChange(event.target.value.trim());

            // Erase contents of the input control...
            setLocalQuery('');
        }
    };

    const submitQuery = () => {
        if (localQuery.trim() !== '') {
            onChange(localQuery.trim());
            setLocalQuery('');
        }
    };

    // Get the function handler for us to communicate a change in QueryContext to any listeners
    const { onChange } = useQuery();

    return (
        <Paper className="bottom-bar" elevation={3} square>
            <Stack spacing={1} style={{ width: textFieldWidth, margin: '0 auto', padding: '0 10px' }}>
                <Stack direction="row" spacing={12} alignItems={"center"}>
                    <TextField
                        multiline
                        rows={2}
                        fullWidth
                        variant="outlined"
                        placeholder={config.languages.find(lang => lang.code === language)?.texts.message}
                        value={localQuery}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#2A548C', // Change outline color
                                },
                                '&:hover fieldset': {
                                    borderColor: '#2A548C', // Change outline color on hover
                                },
                                borderRadius: '4px', // Change rounded corners
                            },
                        }}
                        className="text-field"
                    />
                    <SubmitButton
                        variant="contained"
                        color="primary"
                        onClick={submitQuery}
                        disabled={localQuery.trim() === ''}
                        className="submit-button"
                    >
                        <ArrowUpwardIcon />
                    </SubmitButton>
                </Stack>
                <Typography style={{ textAlign: 'center' , fontSize:'10px', color:'#5c5b5b'}}>
                    {config.languages.find(lang => lang.code === language).texts.beta}
                </Typography>
            </Stack>
        </Paper>
    );
}

export default Query;
import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Styled components
const MessageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    marginLeft: '20%',
    [theme.breakpoints.down('lg')]: {
        marginLeft: 0,
        padding: theme.spacing(1),
      },
}));

const Circle = styled(Box)(({ theme }) => ({
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        width: 30,
        height: 30,
      },
}));

const NameLetter = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16), // Font size for h7
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(20), // Font size for h6
    },
  }));

const ChatBubble = styled(Box)(({ theme }) => ({
    backgroundColor: '#f5cbcb',
    borderRadius: 10,
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(5),
    maxWidth: '60%',
    position: 'relative',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
        maxWidth: '90%', 
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
}));

const UsernameTag = styled(Typography)(({ theme }) => ({
    fontSize: '0.8rem',
}));

const MainTextContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    paddingRight: theme.spacing(6)
}));

const ChatLoading = ({warningMessage}) => {
    return (
        <MessageContainer aria-live='polite'>
            <Circle>
                <NameLetter color="white">
                    L
                </NameLetter>
            </Circle>
            <ChatBubble>
                <UsernameTag>LISA</UsernameTag>
                <MainTextContainer>
                    <Typography fontWeight={'bold'} color={'#541f1f'}> {warningMessage} </Typography>
                </MainTextContainer>
            </ChatBubble>
        </MessageContainer>
    );
};

export default ChatLoading;

import React, { useEffect, useState } from 'react';

import { useQuery } from './QueryContext';
import { Spinner } from 'react-bootstrap';
import Plot from 'react-plotly.js';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import config from "../utils/config.json";
import axios from 'axios'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import  ThumbDownIcon  from '@mui/icons-material/ThumbDown';
import {Button, ButtonGroup, Typography } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useNavigate } from "react-router-dom";
import UserQuestion from './UserQuestion';
import ChatResponse from './ChatResponse';
import ChatLoading from './ChatLoading';
import ChatWarning from './ChatWarning';
import ChatNLQResponse from './ChatNLQResponse';
import ChatNLCConfirm from './ChatNLCConfirm';


function Responses(props) {                
    const [ localResponses, setLocalResponses ] = useState('');   
    const navigate = useNavigate()
      
    // Store an array of posts for differentiated rendering of posts as appropriate
    const [ postsArray, setPostsArray ] = useState([]);

    const [ currentQuestion, setCurrentQuestion ] = useState('');

    const [ isLoading, setIsLoading ] = useState(false);

    const username = sessionStorage.getItem('username');
    

    // Helper method to append a single post to our postsArray State
    const addPostToPostsArray = (post) => {
        setPostsArray([...postsArray, post]);
    }
   
    // Do nothing, no purpose for this at the moment.
    function Responses_onChange(e) {
        // Do nothing, no purpose for this at the moment.
    }
  
    // Monitor for value changes from Query...
    const { value } = useQuery();

    // Need to wrap updating of local state in useEffect dependent on changes to value; so as not to 
    // end up with an infinite loop when rendering
    // Monitor value for state changes, and invoke postQuery whenever change detected...
    useEffect(() => {
        //setLocalResponses(`${localResponses}\n${value}`); // Force a repaint...
        setCurrentQuestion(value);
    
        if(value.length > 0) {            
            postQuery(value);            
        }
    }, [value]);

    // Monitor for changing of currentQuestion to force render...
    useEffect(() => {

    }, [currentQuestion])

    // Monitor postsArray for state changes, and invokve processPostsArray whenever change detected...
    useEffect(() => {
        processPostsArray(postsArray);
    }, [postsArray]);

    const processNLQResponseIntoString = (data) => {
        var response = "";
        var title = data['titleRecommendation'];
        var resultCount = data['results'].length;
        var noResultsFound = data['noResultsFound'];

        // Build up the response...
        response = ``;

        // We have a taFble of results to share...
        if(resultCount > 0) {
            // Start with the title...
            response = `${title}`;

            // Walk the column headers... to build a header
            var header = '';
            var columnHeaders = data['columnHeaders'];
            columnHeaders.forEach(columnHeader => {
                var columnHeaderDescription = columnHeader['description'];
                header = `${header}\t${columnHeaderDescription}`;
            });
            // Add the header to our response...
            response = `${response}\n\t${header}`;

            // Walk the rows of results and add to response...
            var results = data['results'];
            results.forEach(row => {
                // Build the row string
                var rowString = "";
                row.forEach(column => {
                    rowString = `${rowString}\t${column}`;
                });        
                // Add the row to our response...
                response = `${response}\n\t${rowString}`;    
            });

        } else {
            // We don't have results...                        
            response = `${noResultsFound}`;
        }

        return response;
    }

    const processNLHResponseIntoString = (data) => {
        var response = "";
        /* 
        {
            "question" : "...",
            "answer" : "...",
            "sources" : "...",
            "source_documents" : [
                {
                    "page_content" : "...",
                    "metadata" : {
                        "source" : "document name",
                        "page" : pageNumber
                    }
                    "type" : "Document"
                }
            ]
        }
        */

        // Add the answer
        response = data['answer'];

        // Append the source documents metadata (source, page)
        var source_documents = response['sources'];
        source_documents.forEach(source_document => {
            response = `${response}\n\t${source_document['filename']}, page ${source_document['pageNumberRange']}`
        });

        return response;
    }

    const processNLCResponseIntoString = (data) => {
        var response = "";
        /* 
        {
            'naturalLanguageQuery' : "...",
            'commandSummaryConfirmation' : "...",
            'clientQuestion' : {...},
            'cloudResponse' : {...}
        }
        */  

        // Add the answer
        response = data['commandSummaryConfirmation'];

        // Append the source documents metadata (source, page)
        // var source_documents = response['sources'];
        // source_documents.forEach(source_document => {
        //     response = `${response}\n\t${source_document['filename']}, page ${source_document['pageNumberRange']}`
        // });

        return response;
    }
    
    const postQuery = async ( nlq ) => {
        setIsLoading(true);
        console.log(`DEBUG: posting for nlq: ${nlq}`);
        setLocalResponses(`${localResponses}\nQUESTION: ${nlq}\n`);
        var host = window.location.hostname;

        console.log(`Classification: ${props.classification}`)
        console.log(`Language: ${props.language}`)

        const body = JSON.stringify({
            "question": nlq,
            "fullyParameterizedParameters":[],
            "partiallyParameterizedParameters":[],
            "cache":true,
            "context": {
                "productName": props.product,
                "productVersion": props.vers,
                "language":props.language,
                "classification": props.classification
            }
         });
         const header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'cloudtoken': sessionStorage.getItem('idToken'),
            
        };
        await fetch(window.apiURL , {mode:'cors', method:'POST', headers:header, body: body })
            .then((response) => {
                if (response["status"]==401)
                {
                    if (window.confirm("Your session has expired\n would you like to log out and back in"))
                    {
                        sessionStorage.clear();
                        sessionStorage.setItem("username","")
                        navigate(`/login`)
                    }
                }
                return response.json();
                
            })            
            .then((data) => {
                if (data['statusCode']==200 )
                {    
                    console.log(data);
                    const singlePost = {
                        "naturalLanguageQuery" : nlq,
                        "classification" : data['body']['classification'],
                        "response" : data['body']
                        };
                    
                    console.log(`addPostToPostsArray: ${singlePost}`)
                    addPostToPostsArray(singlePost)
                    setIsLoading(false);
                    setCurrentQuestion("");
                }
                else if ([401, 403].includes(data["statusCode"] ) )
                    {
                        sessionStorage.clear();
                        sessionStorage.setItem("username","")
                        navigate(`/login`)                    
                    }   
                    
                else if(data['statusCode'] == 504 )
                    { 
                       setTimeout(() => 
                            {
                            console.log("waiting")
                            fetch(window.apiURL, {mode:'cors', method: 'POST', headers: header, body: body}).then(
                                (responce) => responce.json()).then((data) =>{
                                    const singlePost = {
                                        "naturalLanguageQuery" : nlq,
                                        "classification" : data['body']['classification'],
                                        "response" : data['body']
                                        };
                                        setIsLoading(false);
                                        setCurrentQuestion("");
                                        console.log(`addPostToPostsArray: ${singlePost}`)
                                        addPostToPostsArray(singlePost)
                                });
                            }
                        , 10000);                                         
                    }
                else {
                    const badAnser ={
                        "naturalLanguageQuery" : nlq,
                        "classification" :"msg",
                        "response": {"answer":data['message']}};
                        setIsLoading(false);
                        setCurrentQuestion("");
                        addPostToPostsArray(badAnser);
                }
      

                // Create a post based on the data, and add it to our state...

            })
            .catch((err) => {                
                setIsLoading(false);
                console.log(err);
                const badAnser ={
                    "naturalLanguageQuery" : nlq,
                    "classification" :"msg",
                    "response": {"answer":  err["message"]}};
                    setCurrentQuestion("");
                    addPostToPostsArray(badAnser);

                                
            });

    }    

    // When postsArray changes, re-process all of the posts to present them to user...
    const processPostsArray = async (postsArray) => {
        // Walk through the postsArray and process each post into a string for presentation in our textArea control...
        var stringPresentation = "";
        postsArray.forEach(post => {
            var stringData = "";

            console.log(`postsArray.forEach: ${post}`)
            if(post['classification'] === 'nlq')
                stringData = processNLQResponseIntoString(post['response']);
            else if(post['classification'] === 'nlc')
                stringData = processNLCResponseIntoString(post['response']);
            else if(post['classification'] === 'nlh')
                stringData = processNLHResponseIntoString(post['response']);
            stringPresentation = `${stringPresentation}\nQUESTION[${post['classification']}]: ${post['naturalLanguageQuery']}\nANSWER: ${stringData}\n`;
        });
        setLocalResponses(stringPresentation);
    }

    // After render, this scrolls the textArea to the bottom.
    useEffect(() => {
        //const area = textArea.current;
        //area.scrollTop = area.scrollHeight;
        document.getElementById('ResponseTextAreaID').scrollIntoView({ behavior: 'smooth', block: 'end' });
        var objDiv = document.getElementById('ResponseTextAreaID');
        objDiv.scrollTop = objDiv.scrollHeight
    });

    
    const processIntoHTML = (post, index) => {
        var naturalLanguageQuery = post['naturalLanguageQuery'];
        var classification = post['classification'];
        var response = post['response'];

        switch(classification) {
            case "nlq" : return processNLQIntoHTML(index, naturalLanguageQuery, classification, response);                
            case "nlc" : return processNLCConfirmIntoHTML(index, naturalLanguageQuery, classification, response);
            case "nlh" : return processNLHIntoHTML(index, naturalLanguageQuery, classification, response);        
            default: return processQueryIntoHTML(index, naturalLanguageQuery, classification, response);
        }
    }

    const processQueryIntoHTML = (index, naturalLanguageQuery, classification, response) => {
        return (
            <div className='BeforeResponse' key={index}> 
                <UserQuestion 
                        username={username}
                        naturalLanguageQuery={naturalLanguageQuery}
                        />
                   <ChatWarning warningMessage={response['answer']}/>               
               <br></br>
            </div>
        )
    }

    async function incrementFeedback(recordId, feedbackType) {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'cloudtoken': sessionStorage.getItem('idToken'),
        };
    
        const todo = {
            "recordId": recordId,
            "feedbackType": feedbackType
        };

        const requestUrl = config.feedback_url;

      
            await fetch(requestUrl, {
                method: 'POST',
                mode:'cors', 
                headers: headers,
                body: JSON.stringify(todo)
            }).then((response) => {
                return response.json()
            }).catch((error) => {
                console.error("error:",error)
            })       
        
    }

    function add_like(ans_id, event){
        const nextSibling = event.currentTarget.nextSibling;
        console.log(event.currentTarget)
        console.log(nextSibling);
        if (nextSibling) {
            console.log("DEBUG: adding feedback, like");
            incrementFeedback(ans_id, "likes");
            event.currentTarget.classList.add('chosen');
            nextSibling.disabled = true;
        }
    }

    function add_dislike(ans_id, event){   
        const previousSibling = event.currentTarget.previousSibling;
        console.log(event.currentTarget)
        console.log(previousSibling);
        if (previousSibling) {
            console.log("DEBUG: adding feedback, dislike");
            incrementFeedback(ans_id, "dislikes");
            event.currentTarget.classList.add('chosen');
            previousSibling.disabled = true;
        }
    }
    
    //So these are the functions that push confirm/deny back to the server to determine whether to execute NLC command or not
    const executeNLC = async(nlcCommand, questionParams, setNlcResponse, MainTextContainer, Divider) => {

        //Does it even reach here?
        //Not consistently...
        //I've seen it hang, then repost the original question right back to the server? Why?

        console.log("DEBUG passed to executeNLC");
        console.log("DEBUG " + JSON.stringify(nlcCommand))
        console.log("DEBUG " + JSON.stringify(questionParams))

        //So, I think the fetch actually pushes the request to wherever...
        
        //We don't need to pass the nlq... it's already in nlcCommand
        //Was it really throwing errors because of a semicolon? Possibly...
        const nlq = nlcCommand['question'];

        setIsLoading(true);
        console.log(`DEBUG: posting for nlc-confirm for nlq: ${nlq}`);
        //console.log(JSON.stringify(nlcCommand));
        setLocalResponses(`${localResponses}\nQUESTION: ${nlq}\n`);
        var host = window.location.hostname;

        const body = JSON.stringify({
            "question": nlq,
            "fullyParameterizedParameters":[],
            "partiallyParameterizedParameters":[],
            "cache":true,
            "context": {
                "productName": props.product,
                "productVersion": props.vers,
                "language":props.language,
                "classification": nlcCommand['classification']
            },
            "nlcCommand": nlcCommand,
            "questionParams": questionParams
         });
         const header = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'cloudtoken': sessionStorage.getItem('idToken'),
            
        };
        //Waits for the server response with the results of the commands...
        await fetch(window.apiURLexec , {mode:'cors', method:'POST', headers:header, body: body })
            .then((response) => {
                if (response["status"]==401)
                {
                    if (window.confirm("Your session has expired\n would you like to log out and back in"))
                    {
                        sessionStorage.clear();
                        sessionStorage.setItem("username","")
                        navigate(`/login`)
                    }
                }
                return response.json();
                
            })            
            .then((data) => {
                if (data['statusCode']==200 )
                {    
                    console.log(data);
                    const singlePost = {
                        "naturalLanguageQuery" : nlq,
                        "classification" : data['body']['classification'],
                        "response" : data['body']
                        };
                    
                    console.log(`addPostToPostsArray: ${singlePost}`)
                    // So, we append the post to postsArray, and processPostsArray listens for changes to postsArray, then runs to re-render
                    // Actually, we want to stick it all in ChatNLCConfirm / ChatNLCRequest
                    //addPostToPostsArray(singlePost)
                    setIsLoading(false);
                    setCurrentQuestion("");

                    //Set the NLC
                    //Add divider, make it look pretty
                    setNlcResponse(<React.Fragment>
                        <MainTextContainer>
                        <Divider variant="middle" />
                        <br />
                        {/*Iterate over the executeMethods and report the name and result*/}
                        {data['body']['executeMethods'].map((executeMethod, index2) => (     
                            <Box>                                    
                            {processNLCExecuteIntoHtml(executeMethod, index2)}                        
                            </Box>
                        ))}
                        <Typography variant="body1"><br /></Typography>
                        <Typography variant="h6" fontWeight="bold">
                            {config.languages.find(lang => lang.code === props.language).texts.doSomethingElse}
                        </Typography>
                        </MainTextContainer></React.Fragment>)

                }
                
                else {
                    const badAnser ={
                        "naturalLanguageQuery" : nlq,
                        "classification" :"msg",
                        "response": {"answer":data['message']}};
                        setIsLoading(false);
                        setCurrentQuestion("");
                        addPostToPostsArray(badAnser);
                }
      

                // Create a post based on the data, and add it to our state...

            })
            .catch((err) => {                
                setIsLoading(false);
                console.log(err);
                const badAnser ={
                    "naturalLanguageQuery" : nlq,
                    "classification" :"msg",
                    "response": {"answer":  err["message"]}};
                    setCurrentQuestion("");
                    addPostToPostsArray(badAnser);

                                
            });   
        
    }

    function confirm_nlc(nlcCommand, questionParams, setNlcResponse, MainTextContainer, Divider, event){
        //So, when it does nothing with the confirm-nlc... it's because nextSibling is null here
        //not sure what it does for me, so omitting for now
        const nextSibling = event.target.nextSibling;
        console.log("DEBUG passed to confirm_nlc");
        //console.log("DEBUG " + nextSibling.toString())
        //if (nextSibling) {
            //incrementFeedback(ans_id, "likes");
            console.log("DEBUG found nextSibling, passing to executeNLC");
            executeNLC(nlcCommand, questionParams, setNlcResponse, MainTextContainer, Divider);
            event.target.classList.add('chosen');
            //Just to omit nextSibling conditional above, without deleting code
            if (nextSibling){
            nextSibling.disabled = true;
            }
        //}
    }

    //Basically does nothing with the parameters and sends nothing back to the server as-is
    function deny_nlc(nlcCommand, event){   
        const previousSibling = event.target.previousSibling;
        if (previousSibling) {
            //incrementFeedback(ans_id, "dislikes");
            //DO NOTHING HERE??
            //DO WE NEED TO SEND ANYTHING BACK TO THE SERVER??
            //Maybe we'll eventually send feedback to AWS from here
            event.target.classList.add('chosen');
            previousSibling.disabled = true;
        }
    }

        
    const processNLHIntoHTML = (index, naturalLanguageQuery, classification, response) => {
        /* 
        {
            "question" : "...",
            "answer" : "...",
            "sources" : "...",
            "source_documents" : [
                {
                    "page_content" : "...",
                    "metadata" : {
                        "source" : "document name",
                        "page" : pageNumber
                    }
                    "type" : "Document"
                }
            ]
        }
        */        
        
        // Split the answer by newlines...
        var answerBodyArray = response['answer']['body'].split("\n");
        var answerSource = response['answer']['sources'];
        var answerTitle = response['answer']['title'];
        var answerIntro = response['answer']['introduction'];
        var answerConclusion = response['answer']['conclusion'];

        if (answerSource.length == 0 || answerSource[0]["documentTitle"] === ""){
            return (
                <div className='BeforeResponse' key={index}> 
                    <UserQuestion 
                        username={username}
                        naturalLanguageQuery={naturalLanguageQuery}
                        />
                   <ChatWarning warningMessage={"I don't have enough information to answer that question"}/>           
                   <br></br>
                </div>
            )
        }

        return (            
            <div className='NLHResponse' key={index}> 
                <br></br>
                <UserQuestion 
                        username={username}
                        naturalLanguageQuery={naturalLanguageQuery}
                        />

                <ChatResponse
                    answerTitle={answerTitle}
                    answerIntro={answerIntro}
                    answerBodyArray={answerBodyArray}
                    answerConclusion={answerConclusion}
                    answerSource={answerSource}
                    language={props.language}
                    add_like={add_like}
                    add_dislike={add_dislike}
                    response={response}
                    />
                <Typography> Question ID: {response['answer']['id']}</Typography>
            </div>
        )
    }

    const processNLCConfirmIntoHTML = (index, naturalLanguageQuery, classification, response) => {
        /* 
        {
            'naturalLanguageQuery' : "...",
            'commandSummaryConfirmation' : "...",
            'clientQuestion' : {...},
            'cloudResponse' : {...}
        }
        */        
        
        // Split the answer by newlines...
        var answerBodyArray = response['commandSummaryConfirmation'].split("\n");
        //var answerTitle = response['answer']['title'];
        //var answerIntro = response['answer']['introduction']
        //var answerConclusion = response['answer']['conclusion']
        var nlcCommand = response['cloudResponse'];
        var questionParams = response['question_parameters'];

        console.log(questionParams)

        return (            
            <div className='NLCConfirm' key={index}> 
                <br></br>
                <UserQuestion 
                        username={username}
                        naturalLanguageQuery={naturalLanguageQuery}
                        />

                <ChatNLCConfirm
                    //answerTitle={answerTitle}
                    //answerIntro={answerIntro}
                    answerBodyArray={answerBodyArray}
                    //answerConclusion={answerConclusion}
                    //answerSource={answerSource}
                    //nlq={naturalLanguageQuery}
                    nlcCommand={nlcCommand}
                    questionParams={questionParams}
                    language={props.language}
                    confirm_nlc={confirm_nlc}
                    deny_nlc={deny_nlc}
                    response={response}
                    add_like={add_like}
                    add_dislike={add_dislike}
                    />
            </div>
        )
    }

    const processNLCExecuteIntoHtml = (executeMethod, index2) => {

        var imageExists = executeMethodContainsImage(executeMethod);

        if (imageExists) {
            var imageString = executeMethodGetImage(executeMethod);
        }
        //Determines if it's a success or a fail and creates the HTML element with Check / Cross appropriately
        //Now, need to adjust to make elements render in-line
        if (executeMethod['boolean']){
            var status = <Box bgcolor="#b7eeb6"><span><CheckIcon style={{ color: '#00a900'}}/>Action completed.</span></Box>;
        }
        else{
            var status = <Box bgcolor="#f5cbcb"><span><CloseIcon style={{ color:'#ff0000' }}/>Action failed.</span></Box>;
        }
        const TextBox = styled(Box)(({ theme }) => ({
            display: 'flex',
            flexDirection: 'row', // Stack children horizontall
            alignItems: 'flex-end', // Align children to the right
            gap: theme.spacing(1),  // Space between text and buttons
            [theme.breakpoints.down('sm')]: {
                position: 'relative',
                alignItems: 'flex-start',
                paddingTop: theme.spacing(2),
                paddingLeft: theme.spacing(2)
            }
          }));
        var text = <TextBox><span style={{fontWeight: 'bold'}}>{status}</span> {index2+1} {". "} {executeMethod['name']}</TextBox>;
        if (imageExists){
            return (
                <Box className='getInstances' key={index2}>
                    {text}
                    <br />
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        backgroundColor="#f2f2f2" // Optional: Add a border for visual clarity
                        sx={{ p: '20px' }}
                        >
                            {<img max-height="180px" className="Photo" src={imageString} alt="Chart" />}
                    </Box>
                    <br />
                </Box>          
            );
        } else {
            // Returning a regular NLC result...
            return (
                <Box className='getInstances' key={index2}>
                    {text}
                    <br />
                </Box>                                                                    
            )
        }
        
    }

    const executeMethodContainsImage = (executeMethod) => {        
        var imageExists = false;

        if("Image" in executeMethod) {            
            imageExists = true;
        }

        return imageExists;
    }

    const executeMethodGetImage = (executeMethod) => {        
        var imageString = "";
        if("Image" in executeMethod) {
            imageString = `data:image/png;base64, ${executeMethod["Image"]}`;             
        }

        return imageString;
    }

    const processNLQIntoHTML = (index, naturalLanguageQuery, classification, response) => {
        /*
{
	"question":	"What access levels does Eric Hartman have?",
	"sql":"SELECT ACCESSLVL.DESCRIPT AS [Access Level] FROM ACCESSLVL INNER JOIN BADGELINK ON ACCESSLVL.ACCESSLVID = BADGELINK.ACCLVLID INNER JOIN BADGE ON BADGELINK.BADGEKEY = BADGE.BADGEKEY INNER JOIN EMP ON BADGE.EMPID = EMP.ID WHERE EMP.FIRSTNAME = 'Eric' AND EMP.LASTNAME = 'Hartman'",
	"results":[
		["AL1"],
		["AL2"],
		["AL3"],
		["AL7"]],
	"columnHeaders":[
		{
			"name":"Access Level","
			type":"varchar",
			"description":"Access level description"
		}
	],
	"graphRecommendation":"not graphed",
	"titleRecommendation":"Access Levels for Eric Hartman",
	"noResultsFound":"No access levels were found for Eric Hartman.",
    "image" : "<base64string of image>","
	"classification":"nlq"
}	        
        */
    
        // Check if no results were found...
        if(response['results'] === false) {            
            return  (
                <div className='NLQResponse' key={index}>
                   <UserQuestion 
                        username={username}
                        naturalLanguageQuery={naturalLanguageQuery}
                        />
                    <ChatWarning warningMessage={response['noResultsFound']}/>  
                    <br></br>
                </div>                
            )
        }
        
        /* Detect if there is an image embedded in the response... */
        var imageString = "";
        var imageExists = 0;        
        if("image" in response) {
            imageString = `data:image/png;base64, ${response['image']}`;
            imageExists = 1;
        }

        var chartData = "";
        var chartExists = false;
        /* Detect if there is a JSON chart embedded in the response... */
        /* If no chart, then display tabular results... */
        if("chart" in response && response['chart'].length > 0) {            
            chartData= JSON.parse(response['chart']);      
            console.log(chartData);
            chartExists = true;
        }        

        /* Conveniently capture the mui data-grid-x data requirements from the response */        
        var records = response['records'];
        var columns = response['columns'];
        var columnVisibilityModel = response['columnVisibilityModel'];     
        var title = response['titleRecommendation'];

        console.log('response');
        console.log(response);

        return (
            <div className='NLQResponse' key={index}>
                <UserQuestion 
                        username={username}
                        naturalLanguageQuery={naturalLanguageQuery}
                        />
               
                    <ChatNLQResponse
                        chartExists={chartExists}
                        chartData={chartData}
                        records={records}
                        columns={columns}
                        columnVisibilityModel={columnVisibilityModel}
                        title={title}
                        add_like={add_like}
                        add_dislike={add_dislike}
                        />

                {/* <Typography> Question ID: {response['answer']['id']}</Typography> */}
                           
               <br></br>
            </div>
        )
       
    }    

    const processCurrentQuestionIntoHTML = () => {
        var index = -1;
        if(currentQuestion === "") {
            return        
        } else {
            return (
                <div className='BeforeResponse' key={index}>
                    <UserQuestion 
                        username={username}
                        naturalLanguageQuery={currentQuestion}
                        />
                    <ChatLoading/>
                    <br></br>
                </div>
            )
        }
    }    

    return (  
        // <div className='row'>                 
        <div className='ResponseTextArea' id='ResponseTextAreaID'>                        
            {postsArray.map((post, index) => (                
                processIntoHTML(post, index)                
            ))}                        
            {processCurrentQuestionIntoHTML()}
            
        </div>   
        // </div>   
    )
    

}  

export default Responses;
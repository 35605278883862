import React from 'react';

// Create a new context
const QueryContext = React.createContext(
        "Default Value Here"
);

// Create a new hook to use the context
const useQuery = () => {
    const [query, setQuery] =  React.useContext(QueryContext);

    const handleQuery = (value) => {
        setQuery(value);
    }

    return { value: query, onChange: handleQuery }
};

// Create a Context Provider
const QueryContextProvider = ( { children }) => {
  const [query, setQuery] = React.useState("");

  return (
    <QueryContext.Provider value={[ query, setQuery]}>
      { children }
    </QueryContext.Provider>
  );
};

export { useQuery, QueryContextProvider };
import React from 'react';
import { Box, Typography, IconButton, Divider } from '@mui/material';
import { ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import config from '../utils/config.json';

// Styled components
const MessageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    marginLeft: '20%',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      padding: theme.spacing(1),
    },
  }));
  
  const Circle = styled(Box)(({ theme }) => ({
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        width: 25,
        height: 25,
      },
  }));
  
  const ChatBubble = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    borderRadius: 10,
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(5),
    maxWidth: '60%',
    position: 'relative',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      maxWidth: '90%', 
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }));

  const NameLetter = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16), // Font size for h7
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(20), // Font size for h6
    },
  }));
  
  const UsernameTag = styled(Typography)(({ theme }) => ({
    fontSize: '0.8rem', 
  }));
  
  const MainTextContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2), 
  }));
  
  const SourceContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  }));
  
//   const FeedbackContainer = styled(Box)(({ theme }) => ({
//     position: 'absolute',
//     bottom: theme.spacing(2), 
//     right: theme.spacing(2),  
//     display: 'flex',
//     gap: theme.spacing(1),
//   }));

  const FeedbackContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(2), // Space from the bottom of the ChatBubble
    right: theme.spacing(2),  // Space from the right of the ChatBubble
    display: 'flex',
    flexDirection: 'column', // Stack children vertically
    alignItems: 'flex-end', // Align children to the right
    gap: theme.spacing(1),  // Space between text and buttons
    [theme.breakpoints.down('sm')]: {
        position: 'relative',
        alignItems: 'flex-start',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    }
  }));

  const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1), // Space between buttons
  }));

  const IconButtonStyled = styled(IconButton)(({ theme, isActive, disabled }) => ({
    borderRadius: '50%',
    backgroundColor: isActive ? theme.palette.primary.main : 'transparent',
    color: isActive ? 'white' : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: isActive ? theme.palette.primary.dark : theme.palette.action.hover,
    },
    opacity: disabled && !isActive ? 0.5 : 1, // Adjust opacity for non-active disabled state
  }));
  
  const ChatResponse = ({
    answerTitle,
    answerIntro,
    answerBodyArray,
    answerConclusion,
    answerSource,
    language,
    add_like,
    add_dislike,
    response,
  }) => {

    const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
    const [liked, setLiked] = React.useState(false);
    const [disliked, setDisliked] = React.useState(false);

    const handleLike = (event) => {
        if (!buttonsDisabled) {
        add_like(response['answer']['id'], event);
        setLiked(true);
        setButtonsDisabled(true);
        }
    };

    const handleDislike = (event) => {
        if (!buttonsDisabled) {
        add_dislike(response['answer']['id'], event);
        setDisliked(true);
        setButtonsDisabled(true);
        }
    };

    return (
      <MessageContainer aria-live='polite'>
        <Circle>
          <NameLetter color="white">
            L
          </NameLetter>
        </Circle>
        <ChatBubble>
          <UsernameTag>LISA</UsernameTag>
          <MainTextContainer>
            <Typography variant="h6" fontWeight="bold">
              {answerTitle}
            </Typography>
            <Typography variant="body1" paragraph>
              {answerIntro}
            </Typography>
            {answerBodyArray.map((answer, index) => (
              <Typography variant="body1" key={index}>
                {answer}
                <br />
              </Typography>
            ))}
            <Typography variant="body1" paragraph>
              <br />
              {answerConclusion}
            </Typography>
            <Divider variant="middle" />
          </MainTextContainer>
          <SourceContainer>
            <Typography variant="body2" fontSize='10px'>
              {config.languages.find(lang => lang.code === language).texts.references}
            </Typography>
            {answerSource.map((source_document, index2) => {
              const pageNumberRange = source_document['pageNumberRange'];
              const isValidPageNumber = pageNumberRange && /^\d+$/.test(pageNumberRange);

              return (
                <Typography variant="body2" fontSize='10px' key={index2}>
                  <a 
                    href={isValidPageNumber ? `${source_document['url']}#page=${pageNumberRange}` : source_document['url']} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    style={{ color: 'black' }}
                  >
                    {source_document['documentTitle']}
                  </a>
                  {isValidPageNumber && `: ${config.languages.find(lang => lang.code === language).texts.page} ${pageNumberRange}`}
                </Typography>
              );
            })}
          </SourceContainer>
          <FeedbackContainer>
            <Typography variant="body2" fontSize='16px'>
                {config.languages.find(lang => lang.code === language).texts.results}
            </Typography>
            <ButtonContainer>
                <IconButtonStyled
                onClick={handleLike}
                disabled={buttonsDisabled}
                isActive={liked}
                aria-label='Like'
                >
                <ThumbUpIcon />
                </IconButtonStyled>
                <IconButtonStyled
                onClick={handleDislike}
                disabled={buttonsDisabled}
                isActive={disliked}
                aria-label='Dislike'
                >
                <ThumbDownIcon />
                </IconButtonStyled>
          </ButtonContainer>
        </FeedbackContainer>
        </ChatBubble>
      </MessageContainer>
    );
  };
  
  export default ChatResponse;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { BrowserRouter, HashRouter, Routes, Route, Router, Navigate } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import Lisa from './components/Lisa';
import 'bootstrap/dist/css/bootstrap.min.css'
import LoginPage from './components/loginPage';
import { AppBar } from '@mui/material';


function App() {

  return (
  <React.StrictMode>

      <HashRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={ !!sessionStorage.getItem('accessToken') ? <Lisa /> :<Navigate replace to="/login" />} />
        </Routes>
      </HashRouter>

  </React.StrictMode>
  );
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

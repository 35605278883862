import React from 'react';
import { Box, Typography, IconButton, Divider, Hidden, Stack } from '@mui/material';
import { Check as CheckIcon, HighlightOff as NotInterestedIcon, ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import config from '../utils/config.json';

// Styled components
const MessageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    marginLeft: '20%',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      padding: theme.spacing(1),
    },
  }));
  
  const Circle = styled(Box)(({ theme }) => ({
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        width: 25,
        height: 25,
      },
  }));
  
  const ChatBubble = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    borderRadius: 10,
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(5),
    maxWidth: '60%',
    position: 'relative',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      maxWidth: '90%', 
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }));

  const NameLetter = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16), // Font size for h7
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(20), // Font size for h6
    },
  }));
  
  const UsernameTag = styled(Typography)(({ theme }) => ({
    fontSize: '0.8rem', 
  }));
  
  const MainTextContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2), 
  }));

  const SubtextContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  }));

  const ConfirmContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    bottom: theme.spacing(2), // Space from the bottom of the ChatBubble
    left: theme.spacing(2),  // Space from the right of the ChatBubble
    display: 'flex',
    flexDirection: 'column', // Stack children vertically
    alignItems: 'flex-end', // Align children to the right
    gap: theme.spacing(1),  // Space between text and buttons
    [theme.breakpoints.down('sm')]: {
        position: 'relative',
        alignItems: 'flex-start',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    }
  }));
  
//   const FeedbackContainer = styled(Box)(({ theme }) => ({
//     position: 'absolute',
//     bottom: theme.spacing(2), 
//     right: theme.spacing(2),  
//     display: 'flex',
//     gap: theme.spacing(1),
//   }));

  const FeedbackContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    bottom: theme.spacing(2), // Space from the bottom of the ChatBubble
    right: theme.spacing(2),  // Space from the right of the ChatBubble
    display: 'flex',
    flexDirection: 'column', // Stack children vertically
    alignItems: 'flex-end', // Align children to the right
    gap: theme.spacing(1),  // Space between text and buttons
    [theme.breakpoints.down('sm')]: {
        position: 'relative',
        alignItems: 'flex-start',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    }
  }));

  const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1), // Space between buttons
  }));

  const StyledButtonConfirm = styled(Button)(({ theme, selected, disabled }) => ({
    width: '100%',
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: '16px',
    backgroundColor: disabled
      ? theme.palette.primary.confirmDisabled
      : selected
      ? theme.palette.primary.main
      : theme.palette.primary.main,
    color: disabled
      ? theme.palette.primary.contrastText
      : selected
      ? theme.palette.text.primary
      : theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    border: disabled
      ? 'none' 
      : selected
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.outline}`,
    '&:hover': {
      backgroundColor: disabled
        ? theme.palette.grey[300] 
        : selected
        ? theme.palette.primary.main
        : theme.palette.primary.confirm,
    },
  }));

  const StyledButtonDeny = styled(Button)(({ theme, selected, disabled }) => ({
    width: '100%',
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: '16px',
    backgroundColor: disabled
      ? theme.palette.grey[300] 
      : selected
      ? theme.palette.primary.main
      : theme.palette.background.paper,
    color: disabled
      ? theme.palette.text.disabled 
      : selected
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    border: disabled
      ? 'none' 
      : selected
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.outline}`,
    '&:hover': {
      backgroundColor: disabled
        ? theme.palette.grey[300] 
        : selected
        ? theme.palette.primary.main
        : theme.palette.primary.cancel,
    },
  }));

  const IconButtonStyled = styled(IconButton)(({ theme, isActive, disabled }) => ({
    borderRadius: '50%',
    backgroundColor: isActive ? theme.palette.primary.main : 'transparent',
    color: isActive ? 'white' : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: isActive ? theme.palette.primary.dark : theme.palette.action.hover,
    },
    opacity: disabled && !isActive ? 0.5 : 1, // Adjust opacity for non-active disabled state
  }));

  const ChatNLCConfirm = ({
    answerBodyArray,
    nlcCommand,
    questionParams,
    language,
    confirm_nlc,
    deny_nlc,
    response,
    add_like,
    add_dislike
  }) => {

    console.log('DEBUG creating ChatNLCConfirm element');
    console.log(response)
    //So it definitely got here...

    const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
    const [liked, setLiked] = React.useState(false);
    const [disliked, setDisliked] = React.useState(false);
    
    //Bunch of react states for the confirm and deny buttons
    const [buttonsDisabledExecute, setButtonsDisabledExecute] = React.useState(false);
    // For confirm/deny, there are three states
    //  
    //  confirmButtonText === Actual text in the confirm button -- should change from 'Confirm' to 'Confirmed' on confirm button click
    //  confirmButtonVisible === Whether the confirm button is actually visible or not. Defaults to true, set to false when deny button is clicked
    const [confirmed, setConfirmed] = React.useState(false);
    const [confirmButtonText, setConfirmButtonText] = React.useState(config.languages.find(lang => lang.code === language).texts.confirm);
    const [confirmButtonVisible, setConfirmButtonVisible] = React.useState(true);
    const [denied, setDenied] = React.useState(false);
    const [denyButtonText, setDenyButtonText] = React.useState(config.languages.find(lang => lang.code === language).texts.cancel);
    const [denyButtonVisible, setDenyButtonVisible] = React.useState(true);

    const [nlcResponse, setNlcResponse] = React.useState();

    //Adding back feedback stuff
    const handleLike = (event) => {
      if (!buttonsDisabled) {
      add_like(response['cloudResponse']['id'], event);
      setLiked(true);
      setButtonsDisabled(true);
      }
  };

  const handleDislike = (event) => {
      if (!buttonsDisabled) {
      add_dislike(response['cloudResponse']['id'], event);
      setDisliked(true);
      setButtonsDisabled(true);
      }
  };

    //This should feed into NLC command back to server
    const handleConfirm = (event) => {
      console.log("DEBUG NLC confirm button clicked!");
        if (!buttonsDisabledExecute) {
        setConfirmButtonText(config.languages.find(lang => lang.code === language).texts.confirmed);
        setDenyButtonVisible(false);
        console.log("DEBUG initiating NLC confirm server request");
        confirm_nlc(nlcCommand, questionParams, setNlcResponse, MainTextContainer, Divider, event);
        console.log("DEBUG completed NLC confirm server request");
        setConfirmed(true);
        setButtonsDisabledExecute(true);
        }
    };

    const handleDeny = (event) => {
        if (!buttonsDisabledExecute) {
        setDenyButtonText(config.languages.find(lang => lang.code === language).texts.cancelled);
        setConfirmButtonVisible(false);
        deny_nlc(nlcCommand, event);
        setNlcResponse(<React.Fragment>
          <MainTextContainer>
          <Divider variant="middle" />
          <Typography variant="body1"><br /></Typography>
          <Typography variant="h6" fontWeight="bold">
              {config.languages.find(lang => lang.code === language).texts.wontContinue + " " + config.languages.find(lang => lang.code === language).texts.doSomethingElse}
          </Typography>
          </MainTextContainer></React.Fragment>)
        setDenied(true);
        setButtonsDisabledExecute(true);
        }
    };

    //console.log(add_like.toString());
    console.log('DEBUG created like/dislike handlers');
    //What's the scope of this variable? It seems like it's scoped too far...
    //buttonsDisabled = true;
    console.log('DEBUG the buttons are enabled:' + (!buttonsDisabled).toString());

    //can probably remove the hidden div with the stringified NLC JSON -- but will leave for now for testing purposes
    return (
      <MessageContainer>
        <Circle>
          <NameLetter color="white">
            L
          </NameLetter>
        </Circle>
        <ChatBubble>
          <UsernameTag>LISA</UsernameTag>
          <MainTextContainer>
            <Typography variant="h6" fontWeight="bold">
              {config.languages.find(lang => lang.code === language).texts.stepByStep}
            </Typography>
            <br />
            {answerBodyArray.map((answer, index) => (
              <Typography variant="body1" key={index}>
                {answer}
                <br />
              </Typography>
            ))}
            <Typography variant="h6" fontWeight="bold">
                {config.languages.find(lang => lang.code === language).texts.confirmAndProceed}
                <br />
            </Typography>
          </MainTextContainer>

          <SubtextContainer>
            <ConfirmContainer>
              <Stack width="50vh">
                <Typography variant="body2" fontSize='16px'>
                    <br />
                </Typography>
                <ButtonContainer>
                  {confirmButtonVisible && (
                  <StyledButtonConfirm
                  onClick={handleConfirm}
                  disabled={buttonsDisabledExecute}
                  isActive={confirmed}
                  >
                    <CheckIcon />
                    <Typography variant="body2" fontSize='16px'>
                        {confirmButtonText}
                    </Typography>
                  </StyledButtonConfirm>
                  )}
                  {denyButtonVisible && (
                  <StyledButtonDeny
                  onClick={handleDeny}
                  disabled={buttonsDisabledExecute}
                  isActive={denied}
                  >
                    <NotInterestedIcon />
                    <Typography variant="body2" fontSize='16px'>
                        {denyButtonText}
                    </Typography>
                  </StyledButtonDeny>
                  )}
                </ButtonContainer>
              </Stack>
            </ConfirmContainer>

            <FeedbackContainer>
              <Typography variant="body2" fontSize='16px'>
                  {config.languages.find(lang => lang.code === language).texts.results}
              </Typography>
              <ButtonContainer>
                  <IconButtonStyled
                  onClick={handleLike}
                  disabled={buttonsDisabled}
                  isActive={liked}
                  >
                    <ThumbUpIcon />
                  </IconButtonStyled>
                  <IconButtonStyled
                  onClick={handleDislike}
                  disabled={buttonsDisabled}
                  isActive={disliked}
                  >
                    <ThumbDownIcon />
                  </IconButtonStyled>
              </ButtonContainer>
            </FeedbackContainer>
          </SubtextContainer>

          <Box>{nlcResponse}</Box>
          </ChatBubble>
      </MessageContainer>
    );
  };
  
  export default ChatNLCConfirm;
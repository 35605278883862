// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { CognitoIdentityProviderClient, InitiateAuthCommand,  ForgotPasswordCommand, ConfirmForgotPasswordCommand, RespondToAuthChallengeCommand} from "@aws-sdk/client-cognito-identity-provider";


export const cognitoClient = new CognitoIdentityProviderClient({
  region: window.region,
});

export function signIn  (username, password, setloginstate) {
  const params = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: window.clientId,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };

  const command = new InitiateAuthCommand(params);
  cognitoClient.send(command).then((responce) =>{
    if (responce.ChallengeName){
      sessionStorage.setItem("Session", responce.Session)
      setloginstate(responce.ChallengeName);
    } else if (responce.AuthenticationResult.IdToken) {
      sessionStorage.setItem("idToken", responce.AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", responce.AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", responce.AuthenticationResult.RefreshToken || '');
      sessionStorage.setItem("username",username)
      window.location.href = window.rootURL

      
    }
  }

    ).catch((error)=> {
      if (["NotAuthorizedException", "UserNotFoundException"].includes(error.__type)  ){
      console.log("Sign in failed check username or password");
      alert("Sign in failed check username or password");}
      else {
        alert("Sign in failed for internal error");
        console.log(error);
      }
});
   
  
 
};

export function forgotPwd  (username, setStatus) {
  const params = {
      ClientId: window.clientId, // required
      Username: username, // required
  };

  const command = new ForgotPasswordCommand(params);
  const { CodeDeliveryDetails } = cognitoClient.send(command)
  .then(({ CodeDeliveryDetails } ) => {
    if (CodeDeliveryDetails) {
      console.log(CodeDeliveryDetails);
      setStatus("code")
      alert("A reset code has been sent to the Email regesterd")
      return CodeDeliveryDetails;
    }  
  }).catch((error) => {
    if (["CodeDeliveryFailureException","UserNotFoundException"].includes(error.__type)){
      console.log("Failed to deliver reset code");
      alert("Failed to deliver reset code");
    } else {
      console.log(`Internal Error ${error}`);
      alert("Internal Error");
    }
  });



};

export function ResetPwd  (username, password, code, setstatus) {
  const params ={
    ClientId: window.clientId, // required
    Username: username, // required
    ConfirmationCode: code, // required
    Password: password // required
  }

  const command = new ConfirmForgotPasswordCommand(params);

  cognitoClient.send(command).
    then((response)=> {
      if (response) 
        {
        console.log(response);
        setstatus("init");
        }
    }).catch((error)=> {
      if("ExpiredCodeException" == error.__type){
        alert("code expired please request another code");
        console.log("code expired please request another code");
      }else if (["CodeMismatchException","UserNotFoundException"].includes(error.__type)){
        alert("Failed to reset password");
        console.log("Failed to reset password");
      } else if (["InvalidPasswordException","InvalidParameterException"].includes(error.__type)) {
        alert("Password supplied doens't meet criteria");
      
      } else {
      alert("internal Error");
      console.log(`internal Error ${error}`);
      }
  });
};

export function ResetInitalPwd (ChallengeName, username, newPassword, setState) {

  const params ={
    Session: sessionStorage.getItem("Session"),
    ChallengeName: ChallengeName,
    ClientId: window.clientId,
    ChallengeResponses: { 
      USERNAME: username,
      NEW_PASSWORD: newPassword}
  }
  const command = new RespondToAuthChallengeCommand(params)
  cognitoClient.send(command).then((responce) => {
    if (responce.AuthenticationResult.IdToken){
    sessionStorage.setItem("idToken", responce.AuthenticationResult.IdToken || '');
    sessionStorage.setItem("accessToken", responce.AuthenticationResult.AccessToken || '');
    sessionStorage.setItem("refreshToken", responce.AuthenticationResult.RefreshToken || '');
    sessionStorage.setItem("username",username);
    // setState("init");
    window.location.href = window.rootURL
  };
  }).catch((error)=>{
    console.log(error);
    alert("Internal Error setting new password");
    setState("init");
      
  });
}
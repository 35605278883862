import React from 'react';
import Plot from 'react-plotly.js';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography, IconButton, Divider, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ThumbUp as ThumbUpIcon, ThumbDown as ThumbDownIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import DownloadIcon from '@mui/icons-material/Download';
import config from '../utils/config.json';
import CSVDownload from 'react-csv';

// Styled components
const MessageContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    marginLeft: '20%',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      padding: theme.spacing(1),
    },
  }));
  
  const Circle = styled(Box)(({ theme }) => ({
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        width: 25,
        height: 25,
      },
  }));
  
  const ChatBubble = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    borderRadius: 10,
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(5),
    maxWidth: '60%',
    width: '60%',
    position: 'relative',
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      maxWidth: '90%', 
      width: '90%',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }));

  const NameLetter = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16), // Font size for h7
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(20), // Font size for h6
    },
  }));
  
  const UsernameTag = styled(Typography)(({ theme }) => ({
    fontSize: '0.8rem', 
  }));
  
  const MainTextContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2), 
  }));
  
  const SourceContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  }));

  const FeedbackContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing(2),  
    right: theme.spacing(2),  
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'flex-end', 
    gap: theme.spacing(1),  
    [theme.breakpoints.down('sm')]: {
        position: 'relative',
        alignItems: 'flex-start',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    }
  }));

  const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1), 
  }));

  const IconButtonStyled = styled(IconButton)(({ theme, isActive, disabled }) => ({
    borderRadius: '50%',
    backgroundColor: isActive ? theme.palette.primary.main : 'transparent',
    color: isActive ? 'white' : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: isActive ? theme.palette.primary.dark : theme.palette.action.hover,
    },
    opacity: disabled && !isActive ? 0.5 : 1,
  }));

  const convertToCSV = (records, excludedFields = []) => {
    if (records.length === 0) return '';
  
    // Filter out excluded fields from headers
    const headers = Object.keys(records[0]).filter(field => !excludedFields.includes(field));
  
    const csvRows = [
      headers.join(','), // header row first
      ...records.map(row =>
        headers.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(',')
      )
    ];
  
    return csvRows.join('\n');
  };
  
  // Helper function to escape quotes in CSV
  const replacer = (key, value) => value === null ? '' : value;

  const downloadCSV = (csvData, fileName = 'data.csv') => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  


  const ChatNLQResponse = ({
    chartExists,
    chartData,
    records,
    columns, 
    columnVisibilityModel,
    title,
    add_like,
    add_dislike
  }) => {
    const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
    const [liked, setLiked] = React.useState(false);
    const [disliked, setDisliked] = React.useState(false);

    // Adding flex: 1 gets rid of the empty row that mui adds for some reason...
    const updatedColumns = columns.map(column => ({
      ...column,
      flex: 1
    }));

    const handleLike = (event) => {
        if (!buttonsDisabled) {
        add_like('id', event);
        setLiked(true);
        setButtonsDisabled(true);
        }
    };

    const handleDislike = (event) => {
        if (!buttonsDisabled) {
        add_dislike('id', event);
        setDisliked(true);
        setButtonsDisabled(true);
        }
    };

    const handleDownload = () => {
      const excludedFields = ['id'];
      const csvData = convertToCSV(records, excludedFields);
      downloadCSV(csvData, title);
    };

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xl'));

    return (
      <MessageContainer>
        <Circle>
          <NameLetter color="white">
            L
          </NameLetter>
        </Circle>
        <ChatBubble>
          <UsernameTag>LISA</UsernameTag>
          <MainTextContainer>
            <Stack direction={smallScreen ? 'column' : 'row'} spacing={2} sx={{ paddingBottom: 5, width: '100%', marginBottom: 8, }}>
              {chartExists && (
                 <Box
                    sx={{
                      flex: 1,
                      minWidth: smallScreen ? '100%' : 400,
                      maxWidth: '100%',
                      //width: '100%',
                      height: smallScreen ? 'auto' : '100%',
                      display: 'flex',
                      flexDirection: 'column', 
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                 {/* Header with background color */}
                 <Box
                   sx={{
                     backgroundColor: '#2A548C', 
                     color: '#FFFFFF', 
                     width: '100%',
                     padding: 1, 
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     borderTopLeftRadius: 20, 
                     borderTopRightRadius: 20,
                     marginBottom: -7,
                     zIndex: 10
                   }}
                 >
                   <Typography variant="h6" sx={{ textAlign: 'center' }}>
                     {title}
                   </Typography>
                 </Box>
           
                 {/* Plot */}
                 <Box
                   sx={{
                     flex: 1,
                     width: '100%',
                     height: '100%',
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     
                   }}
                 >
                   <Plot
                     className='AnswerContainer'
                     data={chartData['data']}
                     layout={{
                       ...chartData['layout'],
                       autosize: true, // Make sure Plotly resizes with the container
                     }}
                     style={{ width: '100%', height: '100%' }}
                   />
                 </Box>
               </Box>
              )}
              {true && (
                <Box
                sx={{
                  position: 'relative', // Set relative positioning for the container
                  flex: 1,
                  minWidth:  smallScreen ? '100%' : 350,
                  overflow: 'auto',
                  width: '100%',
                  height: '100%',
                  marginBottom:2
                }}
              >
                <DataGrid
                  rows={records}
                  columns={updatedColumns}
                  columnVisibilityModel={columnVisibilityModel}
                  autosizeOnMount
                  density="compact"
                  columnResizeMode="onChange"
                  autoHeight
                  getRowSpacing={(params) => ({
                    top: params.isFirstVisible ? 0 : 5,
                    bottom: params.isLastVisible ? 0 : 5,
                  })}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 10 },
                    },
                  }}
                  //pageSizeOptions={[5, 10, 15, 20]}
                  pageSize={10}
                  sx={{
                    minWidth: '100%',
                    flex: 1,
                    '.MuiDataGrid-columnHeader': {
                      backgroundColor: '#2A548C',
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                      textAlign: 'center',
                    },
                    '.MuiDataGrid-cell': {
                      borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      fontSize: '0.875rem',
                      fontWeight: 'normal',
                      color: '#333',
                    },
                    '.MuiDataGrid-footerContainer': {
                      borderTop: '1px solid rgba(224, 224, 224, 1)',
                    },
                  }}
                />
                <IconButton
                  aria-label="download"
                  onClick={handleDownload}
                  sx={{
                    position: 'absolute',
                    bottom: 13, 
                    left: 16,  
                    zIndex: 1, 
                  }}
                >
                  <DownloadIcon />
                </IconButton>
              </Box>
              )}
            </Stack>
          </MainTextContainer>
          <FeedbackContainer>
            <Typography variant="body2" fontSize='16px'>
                How were these results?
            </Typography>
            <ButtonContainer>
                <IconButtonStyled
                onClick={handleLike}
                disabled={buttonsDisabled}
                isActive={liked}
                >
                <ThumbUpIcon />
                </IconButtonStyled>
                <IconButtonStyled
                onClick={handleDislike}
                disabled={buttonsDisabled}
                isActive={disliked}
                >
                <ThumbDownIcon />
                </IconButtonStyled>
          </ButtonContainer>
        </FeedbackContainer>
        </ChatBubble>
      </MessageContainer>
    );
  };
  
  export default ChatNLQResponse;
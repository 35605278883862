import React from 'react';
import { Dialog, Toolbar, IconButton, Typography, Stack, Grid, Container, Select, MenuItem, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import config  from '../utils/config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const StyledButton = styled(Button)(({ theme, selected, disabled }) => ({
  width: '100%',
  margin: theme.spacing(1),
  textTransform: 'none',
  fontSize: '16px',
  backgroundColor: disabled
    ? theme.palette.grey[300] 
    : selected
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  color: disabled
    ? theme.palette.text.disabled 
    : selected
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  border: disabled
    ? 'none' 
    : selected
    ? `1px solid ${theme.palette.primary.main}`
    : `1px solid ${theme.palette.outline}`,
  '&:hover': {
    backgroundColor: disabled
      ? theme.palette.grey[300] 
      : selected
      ? theme.palette.primary.main
      : theme.palette.action.hover,
  },
}));

const SignOutButton = styled(Button)(({ theme, selected }) => ({
  width: '25%',
  margin: theme.spacing(1),
  textTransform: 'none',
  fontSize: '16px',
  backgroundColor: selected ? theme.palette.primary.main : theme.palette.background.paper,
  color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.outline}`,
  '&:hover': {
    backgroundColor: selected ? theme.palette.primary.main : theme.palette.action.hover,
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  borderRadius: 4,
  border: `1px solid ${theme.palette.outline}`,
  '& .MuiSelect-select': {
    padding: '10px 20px',
  },
  '& .MuiSelect-icon': {
    color: theme.palette.text.secondary,
  },
}));

const Menu = ({
  open,
  handleClose,
  selectedAction,
  handleActionButtonClick,
  selectedProduct,
  handleProductButtonClick,
  selectedVersion,
  handleVersionButtonClick,
  filteredVersions,
  language,
  handleLanguageChange,
  logo,
  username,
  logout,
  selectedLogOut,
  products
}) => {
  const theme = useTheme();
  
  return (
        <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon fontSize='large'/>
        </IconButton>
        <img src={logo} alt="Logo" width="200" style={{ borderRadius: '8px' }} />
      </Toolbar>
      <Container
        sx={{
          height: 'calc(100vh - 64px)', // Adjust based on toolbar height
          overflowY: 'auto', // Allow scrolling if content overflows
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack
          direction="column" 
          alignItems="center" 
          justifyContent="center"
          spacing={2}
          sx={{ flex: 1 }} // Ensure it uses available space
        >
          <Typography variant="h4" align="center" mt={3} mb={2}>
            {config.languages.find(lang => lang.code === language).texts.whattodo}
          </Typography>
          <Stack width="50vh">
            {/* Question Type Buttons */}
            <StyledButton
              selected={selectedAction === 'nlh'}
              onClick={() => handleActionButtonClick('nlh')}
            >
              {config.languages.find(lang => lang.code === language).texts.gethelp}
            </StyledButton>
            <StyledButton
              selected={selectedAction === 'nlq'}
              onClick={() => handleActionButtonClick('nlq')}
              disabled={!window.local}
            >
              {config.languages.find(lang => lang.code === language).texts.analyze}
            </StyledButton>
            <StyledButton
              selected={selectedAction === 'nlc'}
              onClick={() => handleActionButtonClick('nlc')}
              disabled={!window.local}
            >
              {config.languages.find(lang => lang.code === language).texts.action}
            </StyledButton>
            {/* Product Buttons */}
            <Grid container spacing={2} mt={3}>
              <Grid item xs={12}>
                <Typography variant="h6" align="center" fontWeight={'light'}>
                  {config.languages.find(lang => lang.code === language).texts.system}
                </Typography>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                {Object.keys(config.products).map((product) => (
                  <Grid item xs={4} key={product}>
                    <StyledButton
                      selected={selectedProduct === product}
                      onClick={() => handleProductButtonClick(product)}
                    >
                      {product}
                    </StyledButton>
                  </Grid>
                ))}
              </Grid>
              {/* Version Buttons */}
              {filteredVersions.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" align="center" fontWeight={'light'}>
                      {config.languages.find(lang => lang.code === language).texts.version}
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    {filteredVersions.map((version) => (
                      <Grid item xs={4} key={version}>
                        <StyledButton
                          selected={selectedVersion === version}
                          onClick={() => handleVersionButtonClick(version)}
                        >
                          {version}
                        </StyledButton>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Grid>
            <Typography variant="h6" align="center" fontWeight={'light'}>
              {config.languages.find(lang => lang.code === language).texts.language}
            </Typography>
            {/* Language Dropdown */}
            <StyledSelect value={language} onChange={handleLanguageChange}>
              {config.languages.map(lang => (
                <MenuItem key={lang.code} value={lang.code}>
                  {lang.name}
                </MenuItem>
              ))}
            </StyledSelect>
            {/* Confirm button */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px'
              }}
            >
              <SignOutButton selected={!open} onClick={handleClose}>
                {config.languages.find(lang => lang.code === language).texts.confirm}
              </SignOutButton>
            </Box>
            <Typography variant="h5" align="center" mt={3} mb={2} fontWeight={'bold'}>
              {config.languages.find(lang => lang.code === language).texts.lenels2agent}
            </Typography>
            <Typography variant="h7" align="center" mt={3} mb={2} textTransform={'uppercase'}>
              {username}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SignOutButton selected={selectedLogOut} onClick={() => logout()}>
                {config.languages.find(lang => lang.code === language).texts.signout}
              </SignOutButton>
            </Box>
            <Typography variant="h7" align="center" mt={3} mb={2} fontSize={'12px'}>
              {config.languages.find(lang => lang.code === language).texts.carrier} {config.languages.find(lang => lang.code === language).texts.rights}
            </Typography>
            <Typography variant="h7" align="center" mt={3} mb={2} fontSize={'12px'} fontWeight={'bold'}>
              {config.languages.find(lang => lang.code === language).texts.privacy}
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Dialog>
  );
};

export default Menu;

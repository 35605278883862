import React from 'react';



export default function PasswordHints (props) {
    const password =props["password"];
    let pwdLength ="pwdnoaccept";
    let pwdnum ="pwdnoaccept";
    let pwdspc ="pwdnoaccept";
    let pwdupper ="pwdnoaccept";
    let pwdlower ="pwdnoaccept";
    let regex =new RegExp("")

    if (password.length >=8)  {pwdLength="pwdaccept" }
    regex =new RegExp("\\d");
    if (regex.test(password)) {pwdnum ="pwdaccept"}
    regex =new RegExp("[!@#$%^&*()?<>]");
    if (regex.test(password)) {pwdspc ="pwdaccept"}
    regex =new RegExp("[a-z]");
    if (regex.test(password)) {pwdlower="pwdaccept"}
    regex =new RegExp("[A-Z]");
    if (regex.test(password)) {pwdupper ="pwdaccept"}
    if (["NEW_PASSWORD_REQUIRED", "code"].includes(props["status"])){

    
    return(
       <div className='w-auto'>
        <ul className='pwdList'>
            <li className={pwdLength}><b>Must be at least 8 characters long</b></li>
            <li className={pwdnum}><b>Must contain at least 1 number</b></li>
            <li className={pwdspc}><b>Must contain at least 1 special character</b></li>
            <li className={pwdupper}><b>Must contain at least 1 uppercase</b></li>
            <li className={pwdlower}><b>Must contain at least 1 lowercase</b></li>
        </ul>



       </div>


    )
}
}
//import logo from './logo.svg';
import logo from '../assets/lenels2-logo.png';
import '../styles/App.css';
import React from 'react';
import Responses from './Responses';
import Query from './Query';
import Menu from './Menu';
import { QueryContextProvider } from './QueryContext';
import config  from '../utils/config';

import { Dialog, IconButton, AppBar, Toolbar, Typography, CssBaseline , MenuItem, Box, Grid, Button, Fade, useMediaQuery} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import { ThemeProvider } from '@mui/material/styles';

import theme from './theme.js';


function Lisa() {
  
  const [product, setProduct ] = React.useState("OnGuard")
  const [vers, setVers ] = React.useState("8.2")
  const [language, setLanguage] = React.useState(getInitialLanguage())
  const [question, setQuestion] = React.useState("Help")
  const username = sessionStorage.getItem('username');
  const [open, setOpen] = React.useState(false);

  const [selectedAction, setSelectedAction] = React.useState('nlh');
  const [selectedProduct, setSelectedProduct] = React.useState('OnGuard');
  const [selectedVersion, setSelectedVersion] = React.useState('8.2');
  const [selectedLogOut, setSelectedLogOut] = React.useState(false);

  const products = config.products
  const versions = config.products[selectedProduct] || [];
  const filteredVersions = versions.filter(version => version.trim() !== '');

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const StyledButton = styled(Button)(({ theme, selected, disabled }) => ({
    width: '100px',
    margin: theme.spacing(1),
    textTransform: 'none',
    fontSize: '16px',
    backgroundColor: disabled
      ? theme.palette.grey[300] 
      : selected
      ? theme.palette.primary.main
      : theme.palette.background.paper,
    color: disabled
      ? theme.palette.text.disabled 
      : selected
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    border: disabled
      ? 'none' 
      : selected
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.outline}`,
    '&:hover': {
      backgroundColor: disabled
        ? theme.palette.grey[300] 
        : selected
        ? theme.palette.primary.main
        : theme.palette.action.hover,
    },
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActionButtonClick = React.useCallback((value) => {
    setSelectedAction(value);
  }, []);

  const handleProductButtonClick = React.useCallback((value) => {
    setSelectedProduct(value);
    if (value === 'Elements') {
      setSelectedVersion(''); 
    } else {
      setSelectedVersion(config.products[value]?.[0] || '');
    } 
  }, []);

  const handleVersionButtonClick = React.useCallback((value) => {
    setSelectedVersion(value);
  }, []);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  function getInitialLanguage() {
    const userLanguage = navigator.language;
    // Check if the user's language is in the supported languages
    const supportedLanguages = config.languages.map(lang => lang.code);
    if (supportedLanguages.includes(userLanguage)) {
      return userLanguage;
    } else {
      // Default to English if user's language is not supported
      return 'en-US';
    }
  }

  function logout(e){
    setSelectedLogOut(true);
    sessionStorage.clear();
    window.location.reload();
    //window.location.href = '/login';
  }
  function changeLanguage(e) {
    setLanguage(e.target.value);
  }
  function changeproduct(e){
    setProduct(e.target.value)  
  }
  function changeVersion(e){
    setVers(e.target.value)
  }
  function changeQuestionType(e){
    setQuestion(e.target.value)
    console.log(config.languages.find(lang => lang.code === language)?.questionSelect[question])
  }
  const prods = config.products

  // const questionType = config.languages.find(lang => lang.code === language)?.questionSelect

  console.log(config.languages.find(lang => lang.code === language).texts.hello)
  console.log(prods)
  console.log(prods[product])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
      <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black', zIndex: 1300 }}>
      <Toolbar>
        {isSmallScreen ? (
         <Grid container direction="column" sx={{ height: '100%' }}>
         {/* Top row */}
         <Grid item container alignItems="center" sx={{ flexGrow: 1 }}>
           <Grid item>
             <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleOpen}>
               <MenuIcon fontSize='large' />
             </IconButton>
           </Grid>

           <Grid item xs>
             <Box sx={{ display: 'flex' }}>
               <img src={logo} alt="Logo" width="200" style={{ borderRadius: '8px' }} />
             </Box>
           </Grid>

           <Grid item>
             <Typography variant="h4" fontWeight={'bold'}>
               LISA
             </Typography>
           </Grid>
         </Grid>

         {/* Bottom row */}
         <Grid item container alignItems="center" sx={{ mt: 1 }}>
           <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexGrow: 1 }}>
             <StyledButton
               selected={selectedAction === 'nlh'}
               onClick={() => handleActionButtonClick('nlh')}
             >
               {config.languages.find(lang => lang.code === language).texts.gethelp}
             </StyledButton>
             <StyledButton
               selected={selectedAction === 'nlq'}
               onClick={() => handleActionButtonClick('nlq')}
               disabled={!window.local}
             >
               {config.languages.find(lang => lang.code === language).texts.analyze}
             </StyledButton>
             <StyledButton
               selected={selectedAction === 'nlc'}
               onClick={() => handleActionButtonClick('nlc')}
               disabled={!window.local}
             >
               {config.languages.find(lang => lang.code === language).texts.action}
             </StyledButton>
           </Box>

           <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
             <Typography variant="h7" sx={{ px: '5px' }}>
               {selectedProduct} {selectedVersion}
             </Typography>
           </Box>
         </Grid>
       </Grid>
        ) : (
          // Layout for larger screens
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleOpen}>
                <MenuIcon fontSize='large' />
              </IconButton>
            </Grid>

            <Grid item>
              <img src={logo} alt="Logo" width="200" style={{ borderRadius: '8px' }} />
            </Grid>

            <Grid item xs>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <StyledButton
                  selected={selectedAction === 'nlh'}
                  onClick={() => handleActionButtonClick('nlh')}
                >
                  {config.languages.find(lang => lang.code === language).texts.gethelp}
                </StyledButton>
                <StyledButton
                  selected={selectedAction === 'nlq'}
                  onClick={() => handleActionButtonClick('nlq')}
                  disabled={!window.local}
                >
                  {config.languages.find(lang => lang.code === language).texts.analyze}
                </StyledButton>
                <StyledButton
                  selected={selectedAction === 'nlc'}
                  onClick={() => handleActionButtonClick('nlc')}
                  disabled={!window.local}
                >
                  {config.languages.find(lang => lang.code === language).texts.action}
                </StyledButton>
                <Typography variant="h7" sx={{ px: '5px' }}>
                  {selectedProduct} {selectedVersion}
                </Typography>
                <Typography variant="h4" fontWeight={'bold'}>
                  LISA
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </AppBar>

        <Menu
          open={open}
          handleClose={handleClose}
          selectedAction={selectedAction}
          handleActionButtonClick={handleActionButtonClick}
          selectedProduct={selectedProduct}
          handleProductButtonClick={handleProductButtonClick}
          selectedVersion={selectedVersion}
          handleVersionButtonClick={handleVersionButtonClick}
          filteredVersions={filteredVersions}
          language={language}
          handleLanguageChange={handleLanguageChange}
          logo={logo}
          username={sessionStorage.getItem('username')}
          logout={logout}
          selectedLogOut={selectedLogOut}
          products={products}
        />
        

        <main>
          {/* <Toolbar /> */}
          {/* Main content of your page */}
          <QueryContextProvider>
            <Responses product={selectedProduct} vers={selectedVersion} language={language} classification={selectedAction}></Responses>        
            <Query language={language}></Query>
          </QueryContextProvider>
        </main>
      </div>
      
    </ThemeProvider>
  );

  // return ( 
  //   <Container>       
  //       <Navbar expand="lg" className="bg-body" sticky="top">
  //     <Container>
  //       <Navbar.Brand>
  //         <img src={logo} className="App-logo" alt="logo" />
  //       </Navbar.Brand>
  //       <Navbar.Toggle aria-controls="basic-navbar-nav" />
  //       <Navbar.Collapse id="basic-navbar-nav">
  //         <Nav className="me-auto align-items-center">
  //           <Nav.Item className="px-2">
  //             <label htmlFor="QuestionSelect">
  //               {config.languages.find(lang => lang.code === language)?.texts.questionType}
  //               <select className="form-select p-1 m-1" onChange={changeQuestionType} id="QuestionSelect" name="QuestionSelect">
  //                 {Object.keys(config.languages.find(lang => lang.code === language)?.questionSelect || {}).map((prod, indx) => (
  //                   <option value={prod} key={indx}>{prod}</option>
  //                 ))}
  //               </select>
  //             </label>
  //           </Nav.Item>
  //           <Nav.Item className="px-2">
  //             <label htmlFor="ProductSelect">
  //               {config.languages.find(lang => lang.code === language)?.texts.product}
  //               <select className="form-select p-1 m-1 ps-3 pe-4" onChange={changeproduct} id="ProductSelect" name="ProductSelect">
  //                 {Object.keys(prods).map((prod, indx) => (
  //                   <option value={prod} key={indx}>{prod}</option>
  //                 ))}
  //               </select>
  //             </label>
  //           </Nav.Item>
  //           <Nav.Item className="px-2" style={{ display: product === "Elements" ? "none" : "inline-block" }}>
  //             <label htmlFor="versionSelect">
  //               {config.languages.find(lang => lang.code === language)?.texts.version}
  //             </label>
  //             <select
  //               className="form-select p-1 m-1"
  //               onChange={changeVersion}
  //               id="versionSelect"
  //               style={{ display: product === "Elements" ? "none" : "inline-block" }}
  //             >
  //               {prods[product].map((vers, index3) => (
  //                 <option value={vers} key={index3}>{vers}</option>
  //               ))}
  //             </select>
  //           </Nav.Item>
  //           <Nav.Item className="px-2">
  //             <label htmlFor="LanguageSelect">{config.languages.find(lang => lang.code === language)?.texts.language}</label>
  //             <select
  //               className="form-select p-1 m-1"
  //               value={language}
  //               onChange={changeLanguage}
  //               id="LanguageSelect"
  //               name="LanguageSelect"
  //             >
  //               {config.languages.map((lang) => (
  //                 <option key={lang.code} value={lang.code}>
  //                   {lang.name}
  //                 </option>
  //               ))}
  //             </select>
  //           </Nav.Item>
  //           <Navbar.Text className="px-2">
  //             {config.languages.find(lang => lang.code === language)?.texts.username}: {sessionStorage.getItem('username')}
  //           </Navbar.Text>
  //           <Nav.Item className="px-2 my-auto">
  //             <a className="btn btn-info btn-sm" href="/" onClick={logout}>
  //               <div className="px-2">
  //                 {config.languages.find(lang => lang.code === language)?.texts.logout}
  //               </div>
  //             </a>
  //           </Nav.Item>
  //         </Nav>
  //         <Nav.Item className="ms-auto">
  //           <div>
  //             <p className="text-secondary">Lisa</p>
  //           </div>
  //         </Nav.Item>
  //       </Navbar.Collapse>
  //     </Container>
  //   </Navbar>
        



      
  //     <QueryContextProvider>
  //       <Responses product={product} vers={vers} language={language} classification={config.languages.find(lang => lang.code === language)?.questionSelect[question]}></Responses>        
  //       <Query language={language}></Query>
  //     </QueryContextProvider>
      
  //     </Container>  
  // );
};


export default Lisa

// theme.js
import { createTheme } from '@mui/material/styles';

// Define your custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#2A548C', // Blue color
      contrastText: '#fff', // White text on primary button
      contrastTextDisabled: '#dbdbdb', //Disabled grey for contrastText
      confirm: '#00a900', // Green confirm color 
      confirmDisabled: '#b7eeb6', // Green confirm color for inactive elements
      cancel: '#f5cbcb', // Red cancel color
    },
    secondary: {
      main: '#4377BB', 
      contrastText: '#fff', // White text on secondary button
    },
    background: {
      default: '#F2F2F2', // Default background color
    },
    text: {
      primary: '#000', // Default text color
      disabled: '#474747'
    },
    outline: '#5FBCED',
    divider: '#e0e0e0', // Divider color
  },
  shape: {
    borderRadius: 20, // Rounded corners
  },
  spacing: 4, // Spacing unit
});

export default theme;
// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import pattern from '../assets/mosiac.png';
import logo from '../assets/lenels2-logo.png';
import { useState, React, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  {signIn, forgotPwd, ResetPwd, ResetInitalPwd}  from '../services/authService';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
import PasswordHints from './pwdHints'
import config  from '../utils/config';
import '../styles/login.css'

import { Container, Grid, Typography, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Button, Box } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


function LoginPage  () {
 
  var [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [codebx, setCodebx] = useState(false)
  const [language, setLanguage] = useState(getInitialLanguage()) 
  const [status, setstatus] = useState("init");
  const [ChallengeName, setChalengenem]= useState("");
  const [showPassword, setShowPassword] = useState(false);

  
  const navigate = useNavigate();
// {config.languages.find(lang => lang.code === language)?.texts.signinaccount}
  function getInitialLanguage() {
    const userLanguage = navigator.language;
    // Check if the user's language is in the supported languages
    const supportedLanguages = config.languages.map(lang => lang.code);
 
    if (supportedLanguages.includes(userLanguage)) {
      return userLanguage;
    } else {
      // Default to English if user's language is not supported
      return 'en-US';
    }
  }

  
  useEffect( ()=> {
    if (status != "init")
      {
        setPassword("")
      }
    
  },[status])

  function handleSignIn() {
    if (status =="code") {
      ResetPwd(username, password,code, setstatus);
      
    } else if (status == "init")  {
      signIn(username, password, setstatus);
      console.log('navigating...');
      // navigate('/');
    
    } else if (status == 'NEW_PASSWORD_REQUIRED'){
      ResetInitalPwd(status, username, password, setstatus)
    }

  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
    >
    <Container maxWidth="sm" sx={{ flex: 1 }}>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <img src={logo} alt="Logo" width="300" style={{ borderRadius: '8px' }} />
        <Typography variant="h4" align="center" mt={3} mb={2}>
          <b>{config.languages.find(lang => lang.code === language).texts.hello}</b>
        </Typography>
        <Typography variant="h6" align="center" mb={10}>
          {config.languages.find(lang => lang.code === language).texts.lenels2agent}
        </Typography>
        {status === 'init' && (
          <Typography variant="h6" align="center" mb={4}>
            <b>{config.languages.find(lang => lang.code === language).texts.signintoask}</b>
          </Typography>
        )}
        {status === 'NEW_PASSWORD_REQUIRED' && (
          <Typography variant="h6" align="center" mb={4}>
            {config.languages.find(lang => lang.code === language)?.texts.setAnewPwd}
          </Typography>
        )}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="username"
              label={config.languages.find(lang => lang.code === language).texts.username}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') handleSignIn();
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={config.languages.find(lang => lang.code === language).texts.password}
              />
            </FormControl>
            <Typography style={{ textAlign: 'center' , fontSize:'10px', color:'#5c5b5b', marginTop:'3px'}}>
              {config.languages.find(lang => lang.code === language).texts.beta}
            </Typography>
          </Grid>
          {status === 'code' && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="code"
                label={config.languages.find(lang => lang.code === language).texts.code}
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>
        <Box mt={2} mb={4} textAlign="center">
          {/* Replace this with your PasswordHints component */}
           <PasswordHints password={password} status={status} /> 
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            disabled={!username || !password}
            onClick={handleSignIn}
            sx={{
              width: status === 'init' ? '100px' : '150px',
              boxShadow: 'none', 
              fontSize: '13px',
              borderRadius: '15px',
              backgroundColor: '#2A548C',
              '&:hover': {
                backgroundColor: '#244775',
                boxShadow: 'none', 
              },
            }}
          >
            {status === 'init' ? config.languages.find(lang => lang.code === language)?.texts.signin : config.languages.find(lang => lang.code === language)?.texts.resetpass}
          </Button>
          <Typography variant="body2" mt={2} color="textSecondary">
            {config.languages.find(lang => lang.code === language).texts.privacysignin}
          </Typography>
          {status === 'init' && (
            <Button
              variant="outlined"
              color="primary"
              sx={{
                borderColor: '#4377BB',
                width: '200px',
                fontSize: '13px',
                borderRadius: '15px',
                mt: 2,
                '&:hover': {
                  backgroundColor: '#f0f4fa',
                },
              }}
              onClick={(e) => {
                forgotPwd(username, setstatus);
                e.target.disabled = true;
                setTimeout(() => e.target.disabled = false, 10000);
              }}
            >
              {config.languages.find(lang => lang.code === language)?.texts.forgotpassword}
            </Button>
          )}
        </Box>
      </Box>
    </Container>
    <Box component="footer" textAlign="center" py={2} mt="auto">
        <Typography variant="body2" color="textSecondary">
          {config.languages.find(lang => lang.code === language).texts.carrier}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {config.languages.find(lang => lang.code === language).texts.rights}
        </Typography>
      </Box>
    </Box>
  );

};

export default LoginPage;
